import PageWrapper from '@components/pageWrapper';
import Link from 'next/link';
import Company from 'types/company';
import { GTagCategory } from 'types/googleAnalytics';

interface pageProps {
  companyID: Company;
}

function HomeBrands({ companyID }: pageProps): JSX.Element {
  return (
    <section className="home-brands">
      <PageWrapper>
        <div className="home-brands__inner">
          <div className="home-brands__content">
            <h2>
              Stocking the best {companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'}{' '}
              brands
            </h2>
            <div className="mt-4">
              <Link href="/brands">
                <a className={`cta-btn cta-btn--plain hover:text-white hover:border-white`}>
                  <span className="block text-center">View All Brands</span>
                </a>
              </Link>
            </div>
          </div>
          <div className="home-brands__logos">
            <ul className="logos__list">
              <li>
                <Link href={companyID === Company.Ironmongery ? '/brands/spax' : '/brands/bg'}>
                  <a
                    className="logos-1"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      ga(
                        'send',
                        'event',
                        GTagCategory.Homepage,
                        'Popular Brands',
                        companyID === Company.Ironmongery ? 'spax' : 'bg'
                      );
                    }}
                  >
                    <img
                      src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-1_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/BG.png'
                      }
                      data-lazy-src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-1_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/BG.png'
                      }
                      alt=""
                      width="95"
                      height="42"
                      style={companyID === Company.Electrical ? { transform: 'scale(0.6)' } : {}}
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link
                  href={companyID === Company.Ironmongery ? '/brands/blum' : '/brands/schneider'}
                >
                  <a
                    className="logos-2"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      ga(
                        'send',
                        'event',
                        GTagCategory.Homepage,
                        'Popular Brands',
                        companyID === Company.Ironmongery ? 'blum' : 'schneider'
                      );
                    }}
                  >
                    <img
                      src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-2_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/Schineider.png'
                      }
                      data-lazy-src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-2_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/Schineider.png'
                      }
                      alt=""
                      width="104"
                      height="39"
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href={companyID === Company.Ironmongery ? '/brands/briton' : '/brands/abb'}>
                  <a
                    className="logos-3"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      ga(
                        'send',
                        'event',
                        GTagCategory.Homepage,
                        'Popular Brands',
                        companyID === Company.Ironmongery ? 'briton' : 'abb'
                      );
                    }}
                  >
                    <img
                      src={
                        companyID === Company.Ironmongery
                          ? 'https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-3_2x.png'
                          : 'https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/ABB.png'
                      }
                      data-lazy-src={
                        companyID === Company.Ironmongery
                          ? 'https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-3_2x.png'
                          : 'https://res.cloudinary.com/manutantraders/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/ABB.png'
                      }
                      alt=""
                      width="97"
                      height="39"
                      style={companyID === Company.Electrical ? { transform: 'scale(0.6)' } : {}}
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link
                  href={
                    companyID === Company.Ironmongery ? '/brands/yale' : '/brands/marshalltufflex'
                  }
                >
                  <a
                    className="logos-4"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      ga(
                        'send',
                        'event',
                        GTagCategory.Homepage,
                        'Popular Brands',
                        companyID === Company.Ironmongery ? 'yale' : 'marshalltufflex'
                      );
                    }}
                  >
                    <img
                      src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-4_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/MarshallTufflex.png'
                      }
                      data-lazy-src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-4_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/MarshallTufflex.png'
                      }
                      alt=""
                      width="72"
                      height="39"
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href={companyID === Company.Ironmongery ? '/brands/arrone' : '/brands/marco'}>
                  <a
                    className="logos-5"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      ga(
                        'send',
                        'event',
                        GTagCategory.Homepage,
                        'Popular Brands',
                        companyID === Company.Ironmongery ? 'arrone' : 'marco'
                      );
                    }}
                  >
                    <img
                      src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-5_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/Marco.png'
                      }
                      data-lazy-src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-5_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/Marco.png'
                      }
                      alt=""
                      width="103"
                      height="35"
                      style={companyID === Company.Electrical ? { transform: 'scale(0.95)' } : {}}
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link
                  href={companyID === Company.Ironmongery ? '/brands/dorgard' : '/brands/megaman'}
                >
                  <a
                    className="logos-6"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      ga(
                        'send',
                        'event',
                        GTagCategory.Homepage,
                        'Popular Brands',
                        companyID === Company.Ironmongery ? 'dorgard' : 'megaman'
                      );
                    }}
                  >
                    <img
                      src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-6_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/Megaman.png'
                      }
                      data-lazy-src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-6_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/Megaman.png'
                      }
                      alt=""
                      width="78"
                      height="47"
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href={companyID === Company.Ironmongery ? '/brands/eclisse' : '/brands/mk'}>
                  <a
                    className="logos-7"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      ga(
                        'send',
                        'event',
                        GTagCategory.Homepage,
                        'Popular Brands',
                        companyID === Company.Ironmongery ? 'eclisse' : 'mk'
                      );
                    }}
                  >
                    <img
                      src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-7_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/MK.png'
                      }
                      data-lazy-src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-7_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/MK.png'
                      }
                      alt=""
                      width="110"
                      height="48"
                      style={companyID === Company.Electrical ? { transform: 'scale(0.6)' } : {}}
                    />
                  </a>
                </Link>
              </li>
              <li>
                <Link href={companyID === Company.Ironmongery ? '/brands/Elfa' : '/brands/wylex'}>
                  <a
                    className="logos-8"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      ga(
                        'send',
                        'event',
                        GTagCategory.Homepage,
                        'Popular Brands',
                        companyID === Company.Ironmongery ? 'Elfa' : 'wylex'
                      );
                    }}
                  >
                    <img
                      src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-8_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/Wylex.png'
                      }
                      data-lazy-src={
                        companyID === Company.Ironmongery
                          ? 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/ironmongery/homepage/rebrand/brands/logo-8_2x.png'
                          : 'https://img-cdn.manutantraders.com/image/upload/q_auto,f_auto/electrical/homepage/rebrand/brands/Wylex.png'
                      }
                      alt=""
                      width="85"
                      height="40"
                      style={companyID === Company.Electrical ? { transform: 'scale(0.6)' } : {}}
                    />
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </PageWrapper>
    </section>
  );
}

export default HomeBrands;
